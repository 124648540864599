import dayjs from 'dayjs';

const isPushRentRequestDetail = (_item) => {
  /**
   *  item.objectType 중 Request 종류
   *
   *  'AutoMobileRequest'      // 종사자
   *  'MeritzRequest'          // 메리츠보험
   *  'InsuranceRentRequest'   // 보험
   *  'RentCompanyRentRequest' // 일반
   *  'RentRequestAccident'    // 일반
   *  'RepairShopRequest'      // 협력공업사
   *  'ExternalRequest'        // 카카오 요청
   */
  return _item.objectType?.includes('Request');
};
const isPushNotice = (_item) => {
  return _item.objectType === 'Article' || _item.objectType === 'Notice';
};
const isPushNormalRentCallManage = (_item) => {
  return _item.objectType === 'RetrieveCars' || _item.objectType === 'DeliveryCars';
};
const pushRequestPage = (_item, _props) => {
  const isMonthlyExtend = _item.objectType === 'ExtendedMonthlyRequest';
  const isExternal = _item.objectType === 'ExternalRequest';
  // 확인 푸시도 예약 확정 탭으로 이동되도록 적용.
  const isBookingConfirmMessage =
    isExternal &&
    (_item.messageType === 'selected_response_confirm_request' ||
      _item.messageType === 'external_request_select_response');
  const push = (url) => _props.router.push(url);
  const urlRequestList = '/partner/requestList?tab=selected&page=1';
  const urlNormalRequestDetail = `/partner/detail/normalRequest?id=${_item.objectId}&from_contract=true`;
  const urlRequestDetail = `/partner/rentRequestDetail?id=${_item.objectId}`;

  if (isBookingConfirmMessage) {
    return push(urlRequestList);
  }

  // 월렌트 연장 관련일 때도 요청 상세로 이동.
  if (isExternal || isMonthlyExtend) {
    return push(urlNormalRequestDetail);
  }

  return push(urlRequestDetail);
};

const pushNoticePage = (_item, _props) => {
  const { messageType, objectId } = _item;
  const { push } = _props.router;
  const { use_partner, use_form, use_external } = _props.authStore;

  if (messageType == 'new_partner_notice' || messageType == 'new_external_notice') {
    (use_partner || (use_form && use_external)) && push(`/notice/partner/detail?id=${objectId}`);
  } else if (messageType == 'new_form_notice') {
    use_form && push(`/notice/imsform/detail?id=${objectId}`);
  } else if (messageType == 'new_custom_admin_notice') {
    use_form && push(`/notice/imsform/detail?id=${objectId}`);
  }
  // if (messageType.indexOf('company') != -1) {
  // push(`/notice/company/detail?id=${objectId}`)
  // } else if (messageType.indexOf('new_transfer_car_notice') != -1) { //양수도게시판
  // push(`/notice/transferCar/detail?id=${objectId}`);
  // } else
};

export const moveRequestDetailPage = (item, props) => {
  const { objectType } = item;

  if (isPushRentRequestDetail(item)) {
    return pushRequestPage(item, props);
  }

  if (isPushNormalRentCallManage(item)) {
    return props.router.push(`/partner/normalRentCallManage`);
  }

  switch (objectType) {
    case 'RentalCompanyCoupon':
      return props.router.push('/paymentMethod/coupon?page=1');
    case 'NormalContract':
      return props.router.push(`/normalContract/detail?id=${item.objectId}`);
    case 'RencarClaim':
      return props.router.push(`/contract/detail?id=${item.objectId}`);
    case 'SelfContractInfo':
      return props.router.push('/selfInfo/list?page=1');
    case 'KakaoPolicy':
      return props.router.push('/partner/setting?tab=autoResponseManagement');
    case 'BiddingPoint':
      const currentDate = dayjs();
      const formattedStartDate = currentDate.startOf('month').format('YYYY-MM-DD');
      const formattedEndDate = currentDate.endOf('month').format('YYYY-MM-DD');
      return props.router.push(`/paymentMethod/cash?page=1&excludeClaim=true&startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
    default:
      break;
  }

  if (isPushNotice(item)) {
    return pushNoticePage(item, props);
  }
};

export const makeNotificationObjectList = (array) => {
  try {
    if (Array.isArray(array)) {
      array.map((item) => {
        const { title, date } = contentToObject(item.content);

        date ? (item.date = date) : (item.date = null);
        item.title = title;
        delete item.content;
      });

      return array;
    }

    return null;
  } catch (error) {
    console.log(error);

    return [];
  }
};

const contentToObject = (str) => {
  let result = {};

  try {
    let regex = /(\(\d{4}\/\d{2}\/\d{1,2} \d{2}:\d{2})\).*/;
    const rs = str.match(regex);

    if (rs) {
      result.date = dayjs(rs[0].replace('(', '').replace(')', ''), 'YYYY/MM/DD HH:mm').format(
        'YYYY.MM.DD HH:mm'
      );
      result.title = str.replace(rs[0], '');
    } else {
      result.title = str;
    }
  } catch (error) {
    console.log(error);
    result.title = str;
  }

  return result;
};
