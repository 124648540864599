import axios from 'axios';
import Cookies from 'universal-cookie';

import { serverBaseUrl } from '../config.js';
import { cookieDomain } from '../config.js';
import { IMS_CASH, IMS_POINT } from '../constants/stringTable/format.js';
import AuthUtils from '../utils/AuthUtils';
import errorHandler from './errorHandler';

function getJWT(config) {
  const { serverCookie, jwt } = config;

  if (jwt) {
    return jwt;
  }

  let cookies;

  if (serverCookie) {
    cookies = new Cookies(serverCookie);
  } else {
    cookies = new Cookies();
  }

  return cookies.get(`${process.env.API_ENV}-imsform-jwt`);
}

const instance = axios.create({
  baseURL: serverBaseUrl,
  timeout: 20000,
});

instance.interceptors.request.use(
  async (config) => {
    const jwt = getJWT(config);

    if (jwt) {
      config.headers.Authorization = `JWT ${jwt}`;
    }

    if (config.params?.disableErrorHandler) {
      config.disableErrorHandler = true;
      delete config.params.disableErrorHandler;
    }

    if (config.data?.disableErrorHandler) {
      config.disableErrorHandler = true;
      delete config.data.disableErrorHandler;
    }

    if (config.authorization) {
      config.headers.Authorization = config.authorization;
    }

    if (config?.headers['Content-Type']) {
      if (config?.headers['Content-Type'].indexOf('multipart') != -1) {
        config.timeout = 60000;
      }
    }

    return config;
  },
  (error) => {
    console.log(error);

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.method.toLowerCase() === 'GET'.toLowerCase()) {
      if (response.config.stores) {
        setStoreDefaultData(response.data.defaultInfo, response.config.stores);
      }
    }

    return response;
  },
  (error) => {
    const isClient = typeof window !== 'undefined';

    if (isClient) {
      if (!error.config?.disableErrorHandler) {
        errorHandler(error?.response?.data, error.response?.data?.status);
      }

      return error.response;
    }

    return error.response;
    /**
     * SSR에서 바로 throw 를 하도록 하는것이 목표 였으나,
     * error 를 return 받는 로직의 필요성이 더 커져 사용 중지됨
     * */
    // throw new CallApiError(error);
  }
);

export const setCashPointCookie = ({ cash, point }) => {
  const cookie = new Cookies();
  const option = {
    domain: cookieDomain,
    maxAge: 60 * 60 * 24 * 30,
    path: '/',
  };

  cookie.set(IMS_CASH, cash, option);
  cookie.set(IMS_POINT, point, option);
};

export function setStoreDefaultData(defaultInfo, mobxStore) {
  Boolean(defaultInfo) && setCashPointCookie(defaultInfo);

  if (Boolean(defaultInfo) && Boolean(mobxStore.menuStore) && Boolean(mobxStore.authStore)) {
    mobxStore.menuStore.setBadge(defaultInfo.notification_count);
    mobxStore.authStore.setValue({ key: 'companyCreatedAt', value: defaultInfo.created_at });
    mobxStore.authStore.setValue({ key: 'group_leader', value: defaultInfo.group_leader });
    mobxStore.authStore.setValue({
      key: 'company_id',
      value: defaultInfo.company_id ? defaultInfo.company_id : mobxStore.authStore.userId,
    });

    mobxStore.authStore.setValue({ key: 'cash', value: defaultInfo.cash });
    mobxStore.authStore.setValue({ key: 'point', value: defaultInfo.point });
    mobxStore.authStore.setValue({ key: 'bidding_point', value: defaultInfo.bidding_point });
    mobxStore.menuStore.setSelfContractPendingCount(defaultInfo.pending_signature_count);

    if (!mobxStore.authStore.permissionUserType) {
      const authUtils = new AuthUtils({ authStore: mobxStore.authStore });

      mobxStore.authStore.setValue({
        key: 'permissionUserType',
        value: authUtils.getUserType(mobxStore.authStore),
      });
    }
  }
}

export default instance;
