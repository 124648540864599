import callApi from '../../helpers/callApi';

export const getPartnerRequestListParams = (params) => {
  const serializeForm = {
    ...params,
    target: params.request_state || params.tab,
    start: params.start || params.startDate,
    end: params.end || params.endDate,
    date_option: params.date_option || params.periodOption,
  };

  return serializeForm;
};

export default {
  getMyLocation(params) {
    const serializeForm = {
      page: params.page,
    };
    const data = {
      method: 'GET',
      url: '/v3/requests/my-location',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      disableErrorHandler: params.disableErrorHandler,
    };

    return callApi(data);
  },

  getPartnerRequestList(params) {
    const serializeForm = getPartnerRequestListParams(params);

    const data = {
      method: 'GET',
      url: '/v2/rent-company/rent-requests',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      disableErrorHandler: true,
    };

    return callApi(data);
  },
  getRequestDetail(params) {
    return callApi({
      method: 'GET',
      url: `/v2/rent-requests/${params.requestId}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },

  submitRequest(params) {
    return callApi({
      method: 'POST',
      url: '/v2/rencar-claims',
      data: params,
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  getRencarRequestList(params) {
    return callApi({
      method: 'GET',
      url: `/v2/rent-company/rent-requests?target=${params.type}&page=${params.page}&request_type=insurance`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  setDeliverWithInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-requests/${params.id}/delivered`,
      data: {
        engine_type: params.engine_type,
        customer_car_old: params.customer_car_old,
        delivered_at: params.delivered_at,
        unknown: params.unknown,
        industrial_company: params.industrial_company,
        industrial_company_tel: params.industrial_company_tel,
      },
    });
  },
  setAllDone(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-requests/${params.id}/all_done`,
      data: { done_at: params.done_at },
    });
  },
  getRequestContract(params) {
    return callApi({
      method: 'POST',
      url: `/v2/public/rent-requests/${params.request_id}/contracts`,
      data: { certificate_num: params.certificate_num },
    });
  },
  getRequestPdfAll(params) {
    return callApi({
      method: 'POST',
      url: `/v2/public/rent-requests/${params.request_id}/contracts/pdf`,
      responseType: 'blob',
      data: { certificate_num: params.certificate_num },
    });
  },
};
